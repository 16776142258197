import * as React from "react"
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout/Layout';
import { PageTitleHeader } from '../globalStyles';

import CakePrices from "../content/prices.json";

const Container = styled.div`
  margin: 3rem auto;
  max-width: 1280px;
  padding: 0 20px;
`

const PriceDescriptionContainer = styled.div`
  margin: 10px auto;
  max-width: 960px;
  padding: 0 20px;
`

const CakePricesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CakeContainer = styled.div`
  width: calc(50% - 10px);
  margin: 10px auto;
  text-align: center;
  @media screen and (min-width: 960px) {
    width: calc(33% - 20px);
  }

  img {
    margin-top: 25px;
  }
  p{
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

const CakeSizeText = styled.p`
  font-family: 'Arial';
  font-weight: 200;
`

const getCakeSize = (numbers) => {
  let result = "";
  for (var i=0; i<numbers.length; i++) {
    if (i==0) {
      result = result + numbers[i] + "\"";
    } else {
      result = result + " + " + numbers[i] + "\"";
    }    
  }
  return result;  
}

const getStartingPrice = (price) => {
  return `Starting price from $${price}`
}

const getAverageCost = (numbers) => {
  return `Average cost between $${numbers[0]} - $${numbers[1]}+`
}

export const queryMobile = graphql`
  query {
    cakeImages: allFile(
      filter: {
        sourceInstanceName: {eq: "images"}, 
        relativeDirectory: {eq: "prices"}
      },
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          publicURL
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              quality: 90
              formats: WEBP
              aspectRatio: 0.75
              transformOptions: { fit: COVER, cropFocus: NORTH }
            )
          }
        }
      }
    }
  }
`

const Prices = ({ data }) => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <Layout
      title="Prices"
      description="View approximate price ranges for different sized cakes to get a better idea what your budget can get you."
      pathname={pathname}
    >
      <Container>

        <PageTitleHeader> Prices </PageTitleHeader>

        <PriceDescriptionContainer>
          <p>
            Slicing a cake a together is one of the first tasks you will do together with your loved one.
            Each wedding cake regardless of size receives full attention to detail and is made using the finest ingredients.
            Below we have listed estimated prices for cakes in various sizes.
            Please only use this as a guide and <Link to="/contact">contact us</Link> to create a very personal and beautiful cake for your big day!
          </p>
          <p>
            Fake tiers can be covered and decorated to look like a real cake which will provide more height to the cake at a slightly lower cost than a real cake.
          </p>
        </PriceDescriptionContainer>

        <CakePricesContainer>
          { CakePrices.map((cake) => {
            const cakeImage = data.cakeImages.edges.find(node => node.node.name == cake.image_name);
              return <CakeContainer key={cake.name}>
                <GatsbyImage
                  image={cakeImage.node.childImageSharp.gatsbyImageData}
                  alt={cake.alt}
                  placeholder="blurred"
                  layout="constrained"
                  quality={85}
                  className="desktop"
                />
                <p><b> {cake.name} </b> </p>
                <p> {getCakeSize(cake.size)} </p>
                <p> {getStartingPrice(cake.starting_price)} </p>
                <p> {getAverageCost(cake.average_cost)} </p>
              </CakeContainer>
            }) }
        </CakePricesContainer>

      </Container>
    </Layout>
  )
}

export default Prices

